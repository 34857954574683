import React, { ClipboardEvent, FC, ReactElement, ReactNode, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import Card from '@app/components/card/Card';
import { Form } from 'antd';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import styled from '@emotion/styled';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { FormComponentProps, WrappedFormUtils } from 'antd/es/form/Form';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import { FormFieldDecorators, GetFieldDecoratorOptionsExtended } from '@app/utils/form/form';
import { trim, trimToNull } from '@app/utils/stringUtils';
import { getCountryByCountryCode } from '@app/domain/countries';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import {
  excelRowIntoValidatedAccountData,
  OrganizationDataFromRegistrationFormRef,
  StoreValidatedPayeeAccountWithTypeRequest,
  transformOrganizationData,
  ValidatedAccountExcelRow,
  ValidatedPayeeAccountVisibility,
} from '@mortee/domain/vaildatedPayeeManagement';
import validatedPayeeManagementServices, { storeValidatedAccountPayee } from '@mortee/services/validatedPayeeManagementServices';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import useForm from '@app/hooks/useForm';
import Button from '@app/components/Button';
import { shoot } from '@app/utils/messageLauncher';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import useIsMounted from '@app/hooks/useIsMounted';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import browserHistory from '@app/utils/browserHistory';
import { useParams } from 'react-router-dom';
import useLoadable from '@app/hooks/loadable/useLoadable';
import TransitionLoader from '@app/components/TransitionLoader';
import { AccountVerificationField, getAccountValidationFields } from '@app/domain/accountVerification';
import { isAccountDetailsPartial } from '@app/domain/accountDetailsRepresentations';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import useAppStores from '@app/hooks/useAppStores';
import { showContentOnlyModal, showCustomModalAsync } from '@app/components/Modal';
import CreateValidatedPayeeAccountConfirmationModal from '@mortee/routes/validatedPayeesManagement/CreateValidatedPayeeAccountConfirmationModal';
import {
  convertReadErrorToExplanation,
  readHTMLContentFromClipboardData,
  readHTMLContentFromClipboardDirectly,
} from '@app/utils/clipboardUtils';
import { readExcelRowsFromClipboardHtmlContent } from '@app/utils/excelUtils';
import ErrorModal from '@app/components/ErrorModal';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import { swiftCodeWithSuffixValidator, validateIban } from '@app/domain/accountDetailsValidators';
import {
  convertValidatedAccountValidationLevelToText,
  transformValidationStatusToType,
  ValidatedPayeeAccountValidationStatus,
} from '@app/domain/validatedPayeeAccount';
import { transformValidatedPayeeWithAccounts } from '@app/domain/validatedPayeeWithAccounts';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { SUPPLIER_REGISTRATION_PATTERNS } from '@app/domain/commonSupplierRegistrationFields';
import moment from 'moment';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { notFutureTimestampValidator, onChangeValidateOtherFields, requireOnOtherFieldValue } from '@app/utils/validators';
import { extractLogErrorIdFromError, HttpStatus, isWebErrorContent, RequestError } from '@app/libs/request';
import { ModalFunc } from 'antd/lib/modal/Modal';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';

interface CreateValidatedPayeeAccountFormFields {
  validationType: ValidatedPayeeAccountValidationStatus;
  validationTimestamp: number | undefined;
  effectiveDateTimestamp: number | undefined;
  bankName: string;
  furtherCredit?: string;
  bankLogoResourceId?: string;
  countryCode: string;
  bankCode?: string;
  branchCode?: string;
  iban?: string;
  swiftCode?: string;
  accountNumber?: string;
  localFormat?: string;
  supplierValidationRegistrationNumber?: string;
  storeAsOrganizationalAccount: boolean;
}

const ALL_ACCOUNT_FIELDS: (keyof CreateValidatedPayeeAccountFormFields & keyof MorteeAccountDetailsExtended)[] = [
  'countryCode',
  'bankCode',
  'branchCode',
  'iban',
  'swiftCode',
  'accountNumber',
  'localFormat',
];

const DOMESTIC_ACCOUNT_FIELDS: (keyof CreateValidatedPayeeAccountFormFields & keyof MorteeAccountDetailsExtended)[] = [
  'bankCode',
  'branchCode',
  'accountNumber',
  'localFormat',
];

interface Props extends FormComponentProps<CreateValidatedPayeeAccountFormFields> {}

const CreateValidatedPayeeAccountPage: FC<Props> = observer((props) => {
  const isMountedRef = useIsMounted();
  const { navigationStore } = useInfraStores<MorteeMode>();
  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const { validatedPayeeUniformId } = useParams<{ validatedPayeeUniformId: string }>();
  const modalContext = useModalContext();

  const [validatedPayeeWithAccounts] = useLoadable(async () => {
    const validatedPayeesWithAccountsServerResponses = await validatedPayeeManagementServices.getValidatedPayeesWithAccountsByUniformIds(
      [validatedPayeeUniformId],
    );
    const validatedPayeeWithAccountServerResponse = validatedPayeesWithAccountsServerResponses?.[0];

    if (!validatedPayeeWithAccountServerResponse) {
      throw new Error(`Could not find validated payee with uniform id ${validatedPayeeUniformId}`);
    }

    return transformValidatedPayeeWithAccounts(validatedPayeeWithAccountServerResponse);
  }, [validatedPayeeUniformId]);

  const { form, isFormInvalid, showFormErrors, setShowFormErrors } = useForm(props);

  const { countryCode, bankCode, branchCode, iban, swiftCode, accountNumber, localFormat } = form.getFieldsValue(
    ALL_ACCOUNT_FIELDS,
  );

  const submitDisableClick = (): void => {
    setShowFormErrors('all');
    form.validateFields();
  };

  const onCountryChanged = (newCountry): void => {
    const fieldsToReset = DOMESTIC_ACCOUNT_FIELDS.filter((domesticFieldName) =>
      shouldFieldBeDisabled(newCountry, domesticFieldName),
    );

    form.resetFields(fieldsToReset);
    form.validateFields();
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Record<string, unknown>, values: CreateValidatedPayeeAccountFormFields) => {
      if (errors) {
        return;
      }

      if (validatedPayeeWithAccounts.isResolved()) {
        createValidatedPayeeAccount(values, validatedPayeeWithAccounts.result.aggregatedPayeeData);
      }
    });
  };

  const showCustomTextErrorModal = (bodyContent: ReactNode): ReturnType<ModalFunc> => {
    return showContentOnlyModal((onDone) => (
      <ErrorModal headerContent='Something went wrong' bodyContent={bodyContent} okButtonText='OK' onDone={onDone} />
    ));
  };

  async function extractOrganizationDataFromSupplierValidationRegistrationNumber(
    supplierValidationRegistrationNumber: string | undefined,
    storeAsOrganizationalAccount: boolean,
  ): Promise<OrganizationDataFromRegistrationFormRef | null> {
    if (!supplierValidationRegistrationNumber) {
      return null;
    }

    try {
      const organizationDataServerResponse = await validatedPayeeManagementServices.getOrganizationDataBySupplierValidationRegistrationNumber(
        supplierValidationRegistrationNumber,
      );
      return transformOrganizationData(organizationDataServerResponse);
    } catch (e) {
      const requestError = e as RequestError;
      const errorLogId = extractLogErrorIdFromError(requestError);

      if (
        isWebErrorContent(requestError.responseJSON) &&
        requestError.responseJSON.error === 'SUPPLIER_VALIDATION_PROCESS_DOES_NOT_EXIST'
      ) {
        showCustomTextErrorModal(
          <div>
            <p>Supplier validation registration number {supplierValidationRegistrationNumber} does not exists.</p>
            <p>Error code: {errorLogId}</p>
          </div>,
        );
        throw requestError;
      }

      if (requestError.code === HttpStatus.notFound) {
        if (storeAsOrganizationalAccount) {
          showCustomTextErrorModal(
            <div>
              <p>
                Could not associate Supplier validation registration number {supplierValidationRegistrationNumber} to
                organization.
              </p>
              <p>
                That might be because:
                <ol>
                  <li>This Supplier validation registration number is not linked to any SVM record.</li>
                  <li>This Supplier validation registration number is linked to SVM record with UNKNOWN organization.</li>
                </ol>
              </p>
              <p>Error code: {errorLogId}</p>
            </div>,
          );
          throw requestError;
        } else {
          return null;
        }
      }

      showCustomTextErrorModal(
        <div>
          <p>
            Could not fetch organization data for supplier validation registration number {supplierValidationRegistrationNumber}.
          </p>
          <p>Error code: {errorLogId}</p>
        </div>,
      );
      throw requestError;
    }
  }

  const createValidatedPayeeAccount = async (
    formFields: CreateValidatedPayeeAccountFormFields,
    validatedPayee: AggregatedValidatedPayee,
  ): Promise<void> => {
    setSavingInProgress(true);

    const { supplierValidationRegistrationNumber, storeAsOrganizationalAccount } = formFields;

    let organizationData: OrganizationDataFromRegistrationFormRef | null;

    try {
      organizationData = await extractOrganizationDataFromSupplierValidationRegistrationNumber(
        supplierValidationRegistrationNumber,
        storeAsOrganizationalAccount,
      );
    } catch (e) {
      setSavingInProgress(false);
      return;
    }

    try {
      const payload: StoreValidatedPayeeAccountWithTypeRequest = {
        accountDetails: {
          bankCode: trimToNull(formFields.bankCode),
          branchCode: trimToNull(formFields.branchCode),
          iban: trimToNull(formFields.iban),
          swiftCode: trimToNull(formFields.swiftCode),
          accountNumber: trimToNull(formFields.accountNumber),
          countryCode: trimToNull(
            formFields.bankCode || formFields.branchCode || formFields.localFormat ? formFields.countryCode : null,
          ),
        },
        bankCountryCode: formFields.countryCode,
        bankName: trimToNull(formFields.bankName),
        furtherCredit: trimToNull(formFields.furtherCredit ?? null),
        uniformId: null,
        accountVisibility: storeAsOrganizationalAccount
          ? ValidatedPayeeAccountVisibility.organizationalVisibility
          : ValidatedPayeeAccountVisibility.userOnlyVisibility,
        bankLogoResourceId: null,
        contributingOrganizationId: storeAsOrganizationalAccount ? organizationData?.id ?? null : null,
        supplierValidationRegistrationNumber: trimToNull(formFields.supplierValidationRegistrationNumber),
        validationTimestamp: formFields.validationTimestamp ?? null,
        effectiveDateTimestamp: formFields.effectiveDateTimestamp ?? null,
        type: transformValidationStatusToType(formFields.validationType),
        localFormat: formFields.localFormat ?? null,
      };

      const alerts = await validatedPayeesManagementStore.calcPayeeAccountCreationAlerts(payload, validatedPayee);
      const notices = await validatedPayeesManagementStore.calcPayeeAccountCreationNotices({
        organizationName: organizationData?.name,
      });

      const shouldSave = await showCustomModalAsync(
        (onAccept, onDecline) => {
          return (
            <ModalAppContext {...modalContext}>
              <CreateValidatedPayeeAccountConfirmationModal
                accountValidationRequest={payload}
                accountValidationType={formFields.validationType}
                alerts={alerts}
                notices={notices}
                onConfirm={onAccept}
                onClose={onDecline}
              />
            </ModalAppContext>
          );
        },
        { maskClosable: false },
      );

      if (!shouldSave) {
        return;
      }

      const result = await storeValidatedAccountPayee(validatedPayeeUniformId, payload);

      if (isMountedRef.current) {
        browserHistory.push(navigationStore.generateManageValidatedPayeePageHref(result.payeeUniformId));
      }

      shoot({ type: 'success', closeable: true }, 'Validated payee account created');
    } finally {
      setSavingInProgress(false);
    }
  };

  const handleReadFromClipboard = async (): Promise<void> => {
    let clipboardExcelRowsHtml: string | null = null;
    try {
      clipboardExcelRowsHtml = await readHTMLContentFromClipboardDirectly();
    } catch (e: unknown) {
      let modalErrorContent: string;

      if (e instanceof Error) {
        modalErrorContent = convertReadErrorToExplanation(e.message);
      } else {
        modalErrorContent = convertReadErrorToExplanation(null);
      }

      showContentOnlyModal((onDone) => (
        <ErrorModal headerContent='Paste Error' bodyContent={modalErrorContent} okButtonText='OK' onDone={onDone} />
      ));
      return;
    }

    applyValidatedAccountExcelRowToForm(clipboardExcelRowsHtml);
  };

  const onPaste = (e: ClipboardEvent<HTMLDivElement>): void => {
    if (document.activeElement?.tagName.toLowerCase() !== 'input') {
      const clipboardExcelRowsHtml = readHTMLContentFromClipboardData(e.clipboardData);

      applyValidatedAccountExcelRowToForm(clipboardExcelRowsHtml);
    }
  };

  const applyValidatedAccountExcelRowToForm = (clipboardExcelRowsHtml: string | null): void => {
    if (!clipboardExcelRowsHtml) {
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Paste Error'
          bodyContent='No suitable content found in clipboard'
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return;
    }

    const rows = readExcelRowsFromClipboardHtmlContent(clipboardExcelRowsHtml);

    if (!rows.length) {
      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent='Paste Error'
          bodyContent='Could not find excel rows in clipboard to fill the form'
          okButtonText='OK'
          onDone={onDone}
        />
      ));
      return;
    }

    const convertDateColumnToMoment = (date: string | undefined | null): moment.Moment | null => {
      if (!date) {
        return null;
      }

      const parsedDate = moment.utc(date, 'DD/MM/YYYY');
      if (!parsedDate.isValid()) {
        return null;
      }

      return parsedDate;
    };

    const validatedAccountExcelRow = excelRowIntoValidatedAccountData(rows[0]);

    const validationType = getValidationTypeFromValidatedAccountExcelRow(validatedAccountExcelRow);
    const validationDateMoment = convertDateColumnToMoment(validatedAccountExcelRow.validationDate);
    const effectiveDateMoment = convertDateColumnToMoment(validatedAccountExcelRow.effectiveDate);
    const countryCode = getCountryByCountryCode(validatedAccountExcelRow.countryCode) ? validatedAccountExcelRow.countryCode : '';

    form.setFieldsValue({
      countryCode,
    } as Pick<CreateValidatedPayeeAccountFormFields, 'countryCode'>);

    // We first have to let the right input appear on the screen
    // and then set the value
    window.setTimeout(() => {
      const otherFormFields: PartialNotOptional<Omit<CreateValidatedPayeeAccountFormFields, 'countryCode' | 'localFormat'>> = {
        validationType,
        iban: validatedAccountExcelRow.iban ?? '',
        swiftCode: validatedAccountExcelRow.swiftCode ?? '',
        bankCode: shouldFieldBeDisabled(countryCode, 'bankCode') ? '' : validatedAccountExcelRow.bankCode ?? '',
        branchCode: shouldFieldBeDisabled(countryCode, 'branchCode') ? '' : validatedAccountExcelRow.branchCode ?? '',
        accountNumber: shouldFieldBeDisabled(countryCode, 'accountNumber') ? '' : validatedAccountExcelRow.accountNumber ?? '',
        validationTimestamp: validationDateMoment?.valueOf(),
        effectiveDateTimestamp: effectiveDateMoment?.valueOf(),
        furtherCredit: validatedAccountExcelRow.furtherCredit ?? '',
        bankName: validatedAccountExcelRow.bankName ?? '',
        supplierValidationRegistrationNumber: validatedAccountExcelRow.supplierValidationRegistrationNumber ?? '',
        storeAsOrganizationalAccount: !!validatedAccountExcelRow.storeAsOrganizationalAccount,
      };

      form.setFieldsValue(otherFormFields);
      form.validateFields();
    });
  };

  const getValidationTypeFromValidatedAccountExcelRow = (
    validatedAccountExcelRow: ValidatedAccountExcelRow,
  ): ValidatedPayeeAccountValidationStatus | undefined => {
    if (validatedAccountExcelRow.isExtendedValidation) {
      return ValidatedPayeeAccountValidationStatus.extendedValidation;
    }

    switch (validatedAccountExcelRow.validationType) {
      case 'Direct':
      case 'Linked Account': {
        return ValidatedPayeeAccountValidationStatus.reverseWireValidation;
      }
      case 'Light SV':
      case 'Bank Validation': {
        return ValidatedPayeeAccountValidationStatus.lightweightValidation;
      }
      case 'Internal Validation': {
        return ValidatedPayeeAccountValidationStatus.internalValidation;
      }
    }

    // Select no option - let the user decide
    return undefined;
  };

  const renderValidationTypeDropdown = (name: string, placeholder: string): ReactElement => {
    return (
      <NakedDropdown accessibilityLabel='validation type' name={name} dataTestId={name} placeholder={placeholder}>
        {[
          ValidatedPayeeAccountValidationStatus.reverseWireValidation,
          ValidatedPayeeAccountValidationStatus.lightweightValidation,
          ValidatedPayeeAccountValidationStatus.extendedValidation,
          ValidatedPayeeAccountValidationStatus.internalValidation,
          ValidatedPayeeAccountValidationStatus.lyonsBankValidation,
        ].map((validationType) => {
          const validationTypeText = convertValidatedAccountValidationLevelToText(validationType);
          return (
            <ValidationTypeDropdownIItem key={validationType} value={validationType} textWhenSelected={validationTypeText}>
              <div>{validationTypeText}</div>
            </ValidationTypeDropdownIItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const fieldDecorators = createFieldDecorators(form);

  const defaultBankCodeField = useMemo<AccountVerificationField>(
    () => ({
      id: 'bank-code',
      decorator: (): GetFieldDecoratorOptionsExtended => fieldDecorators.bankCode,
      displayName: 'Bank Code',
      inputType: 'text',
    }),
    [fieldDecorators.bankCode],
  );
  const defaultBranchCodeField = useMemo<AccountVerificationField>(
    () => ({
      id: 'branch-code',
      decorator: (): GetFieldDecoratorOptionsExtended => fieldDecorators.branchCode,
      displayName: 'Branch Code',
      inputType: 'text',
    }),
    [fieldDecorators.branchCode],
  );
  const defaultAccountNumberField = useMemo<AccountVerificationField>(
    () => ({
      id: 'accountNumber',
      decorator: (): GetFieldDecoratorOptionsExtended => fieldDecorators.accountNumber,
      displayName: 'Account Number',
      inputType: 'text',
    }),
    [fieldDecorators.accountNumber],
  );

  const shouldFieldBeDisabled = (
    countryCode: string | undefined | null,
    fieldName: keyof CreateValidatedPayeeAccountFormFields & keyof MorteeAccountDetailsExtended,
  ): boolean => {
    const accountValidationFieldsOfCountry = countryCode ? getAccountValidationFields(countryCode) : null;

    // Countries who do not have any special fields' definition - the field is enabled
    if (!accountValidationFieldsOfCountry) {
      return false;
    }

    // For countries who do have special fields definition:
    // if the definition does not have an entry for the field - it is disabled
    return !accountValidationFieldsOfCountry[fieldName];
  };

  const getFieldDefinition = (
    countryCode: string | undefined,
    fieldName: keyof CreateValidatedPayeeAccountFormFields & keyof MorteeAccountDetailsExtended,
    nonSpecialCountryFieldDefinition: AccountVerificationField,
  ): AccountVerificationField | undefined => {
    if (countryCode) {
      return getAccountValidationFields(countryCode)?.[fieldName];
    }

    return nonSpecialCountryFieldDefinition;
  };

  const renderField = (
    selectedCountryCode: string | undefined,
    fieldName: keyof CreateValidatedPayeeAccountFormFields & keyof MorteeAccountDetailsExtended,
    nonSpecialCountryFieldDefinition: AccountVerificationField,
  ): ReactElement => {
    const fieldDefinition = getFieldDefinition(selectedCountryCode, fieldName, nonSpecialCountryFieldDefinition);

    const decorator = fieldDefinition?.decorator(false);

    // undefined means "let the context set if the input in disabled or not"
    const disabled = shouldFieldBeDisabled(selectedCountryCode, fieldName) ? true : undefined;

    return (
      <FormItemBox key={fieldName} fieldName={fieldName} fieldDecoratorOptions={decorator ?? {}} disabled={disabled}>
        <NakedFormInput
          key={fieldName}
          name={`inpt-validated-account-creation-${fieldDefinition?.id || fieldName}`}
          type={fieldDefinition?.inputType ?? 'text'}
          placeholder={fieldDefinition?.displayName ?? nonSpecialCountryFieldDefinition.displayName}
          disableSuggestion
        />
      </FormItemBox>
    );
  };

  const isFormAccountDetailsPartial = isAccountDetailsPartial({
    countryCode: trimToNull(countryCode),
    bankCode: trimToNull(bankCode),
    branchCode: trimToNull(branchCode),
    swiftCode: trimToNull(swiftCode),
    iban: trimToNull(iban),
    accountNumber: trimToNull(accountNumber),
    localFormat: trimToNull(localFormat),
  });

  const renderHeaderSideContent = (): ReactElement => (
    <PageHeaderCenteredSideContent>
      <Button id='btn-validated-account-creation-from-clipboard' appearance='text' onClick={handleReadFromClipboard}>
        LOAD DATA FROM CLIPBOARD
      </Button>
    </PageHeaderCenteredSideContent>
  );

  return (
    <TransitionLoader loading={!validatedPayeeWithAccounts.isResolved()}>
      <PageWithHeader
        width='full'
        header={
          <PageHeader backButton wrapHeaderTitle sideContent={renderHeaderSideContent()}>
            <PageHeaderTitle
              title={`${validatedPayeeWithAccounts.result?.aggregatedPayeeData.mainName} - Create New Validated Payee Account`}
            />
          </PageHeader>
        }
        onPaste={onPaste}
      >
        <StyledForm
          form={form}
          appearance='corners'
          showErrors={showFormErrors}
          disabled={savingInProgress}
          setShowErrors={setShowFormErrors}
        >
          <StyledCard>
            <WrappingColumns>
              <Column>
                <FieldsTitle>Validation</FieldsTitle>
                <FormItemBox fieldName='validationType' fieldDecoratorOptions={fieldDecorators.validationType} showAsRequired>
                  {renderValidationTypeDropdown('drp-validated-account-creation-validation-type', 'Validation Type')}
                </FormItemBox>
                <FormItemBox fieldName='validationTimestamp' fieldDecoratorOptions={fieldDecorators.validationTimestamp}>
                  <NakedCalendarDatePicker
                    placeholder='Validation Date'
                    id='date-picker-validation-date'
                    accessibilityLabel='Validation Date'
                    disabledDate={(date): boolean => date?.isAfter() ?? false}
                  />
                </FormItemBox>
                <FormItemBox fieldName='effectiveDateTimestamp' fieldDecoratorOptions={fieldDecorators.effectiveDateTimestamp}>
                  <NakedCalendarDatePicker
                    placeholder='Effective Date'
                    id='date-picker-effective-date-timestamp'
                    accessibilityLabel='Effective Date'
                    disabledDate={(date): boolean => date?.isAfter() ?? false}
                  />
                </FormItemBox>
                <FieldsTitle>Bank</FieldsTitle>
                <FormItemBox fieldName='bankName' fieldDecoratorOptions={fieldDecorators.bankName} showAsRequired>
                  <NakedFormInput
                    name='inpt-validated-account-creation-account-bank-name'
                    dataTestId='inpt-validated-account-creation-account-bank-name'
                    type='string'
                    placeholder='Bank Name'
                    disableSuggestion
                  />
                </FormItemBox>
                <FormItemBox fieldName='furtherCredit' fieldDecoratorOptions={fieldDecorators.furtherCredit}>
                  <NakedFormInput
                    name='inpt-validated-account-creation-further-credit'
                    dataTestId='inpt-validated-account-creation-further-credit'
                    type='string'
                    placeholder='Further Credit'
                    disableSuggestion
                  />
                </FormItemBox>
                <FormItemBox fieldName='bankLogoResourceId' fieldDecoratorOptions={fieldDecorators.bankLogoResourceId}>
                  <NakedFormInput
                    name='inpt-validated-account-creation-bank-logo-resource-id'
                    dataTestId='inpt-validated-account-creation-bank-logo-resource-id'
                    type='string'
                    placeholder='Bank Logo Id'
                    disableSuggestion
                  />
                </FormItemBox>
                <FieldsTitle>Supplier Validation</FieldsTitle>
                <FormItemBox
                  fieldName='supplierValidationRegistrationNumber'
                  fieldDecoratorOptions={fieldDecorators.supplierValidationRegistrationNumber}
                  showErrors='all'
                >
                  <NakedFormInput
                    name='inpt-validated-account-creation-supplier-validation-registration-number'
                    dataTestId='inpt-validated-account-creation-supplier-validation-registration-number'
                    type='string'
                    placeholder='Registration Number'
                    disableSuggestion
                  />
                </FormItemBox>
                <FormItemBox
                  appearance='none'
                  fieldName='storeAsOrganizationalAccount'
                  fieldDecoratorOptions={fieldDecorators.storeAsOrganizationalAccount}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='inpt-validated-account-creation-store-as-organizational'
                        data-testid='inpt-validated-account-creation-store-as-organizational'
                      />
                    }
                    label='Create as private account'
                  />
                </FormItemBox>
              </Column>
              <Column>
                <FieldsTitle>Account</FieldsTitle>
                <FormItemBox fieldName='countryCode' fieldDecoratorOptions={fieldDecorators.countryCode} showAsRequired>
                  <CountryDropdown
                    accessibilityLabel='country code'
                    name='drp-validated-account-creation-country-code'
                    dataTestId='drp-validated-account-creation-country-code'
                    currentPayeeCountryCode={validatedPayeeWithAccounts.result?.aggregatedPayeeData.countryCode}
                    onChange={onCountryChanged}
                  />
                </FormItemBox>
                {renderField(countryCode, 'bankCode', defaultBankCodeField)}
                {renderField(countryCode, 'branchCode', defaultBranchCodeField)}
                <FormItemBox fieldName='iban' fieldDecoratorOptions={fieldDecorators.iban}>
                  <NakedFormInput
                    name='inpt-validated-account-creation-iban'
                    dataTestId='inpt-validated-account-creation-iban'
                    type='string'
                    placeholder='IBAN'
                    disableSuggestion
                  />
                </FormItemBox>
                <FormItemBox fieldName='swiftCode' fieldDecoratorOptions={fieldDecorators.swiftCode}>
                  <NakedFormInput
                    name='inpt-validated-account-creation-swift-code'
                    dataTestId='inpt-validated-account-creation-swift-code'
                    type='string'
                    placeholder='SWIFT'
                    disableSuggestion
                  />
                </FormItemBox>
                {renderField(countryCode, 'accountNumber', defaultAccountNumberField)}
                {countryCode === 'MX' && (
                  <FormItemBox fieldName='localFormat' fieldDecoratorOptions={fieldDecorators.localFormat}>
                    <NakedFormInput
                      name='inpt-validated-account-creation-local-format'
                      dataTestId='inpt-validated-account-local-format'
                      type='string'
                      placeholder='CLABE'
                      disableSuggestion
                    />
                  </FormItemBox>
                )}
              </Column>
            </WrappingColumns>
            <ConditionalTooltip
              showTooltip={!isFormInvalid && isFormAccountDetailsPartial}
              title='Account details must have at least one full type of account: Domestic, IBAN or SWIFT'
            >
              <SubmitButton
                id='btn-create-validated-payee-account-submit'
                disabled={isFormInvalid || isFormAccountDetailsPartial}
                onClick={handleOk}
                loading={savingInProgress}
                onDisabledClick={submitDisableClick}
              >
                CREATE
              </SubmitButton>
            </ConditionalTooltip>
          </StyledCard>
        </StyledForm>
      </PageWithHeader>
    </TransitionLoader>
  );
});

export default Form.create<Props>()(CreateValidatedPayeeAccountPage);

const createFieldDecorators = (
  form: WrappedFormUtils<CreateValidatedPayeeAccountFormFields>,
): FormFieldDecorators<CreateValidatedPayeeAccountFormFields> => ({
  validationType: {
    rules: [
      {
        required: true,
        message: 'Pick validation type',
      },
    ],
  },
  validationTimestamp: {
    rules: [
      {
        type: 'number',
      },
      {
        validator: notFutureTimestampValidator(),
      },
    ],
  },
  effectiveDateTimestamp: {
    rules: [
      {
        type: 'number',
      },
      {
        validator: notFutureTimestampValidator(),
      },
    ],
  },
  bankName: {
    rules: [
      {
        required: true,
        transform: trim,
        message: 'Bank Name required',
      },
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.accountBankName,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.accountBankName} characters`,
      },
    ],
  },
  furtherCredit: {
    rules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.furtherCredit,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.furtherCredit} characters`,
      },
    ],
  },
  bankLogoResourceId: {
    rules: [
      {
        pattern: VALIDATION_PATTERNS.uuid,
        transform: trim,
        message: 'Invalid bank logo identifier',
      },
    ],
  },
  swiftCode: {
    rules: [
      {
        validator: swiftCodeWithSuffixValidator,
        transform: trim,
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        message: 'Invalid Character',
      },
    ],
  },
  bankCode: {
    rules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.default.bank,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.default.bank} characters`,
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
        transform: trim,
        message: 'Invalid Bank Code',
      },
    ],
  },
  branchCode: {
    rules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.default.branch,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.default.branch} characters`,
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
        transform: trim,
        message: 'Invalid Branch Code',
      },
    ],
  },
  accountNumber: {
    rules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.default.accountNumber} characters`,
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        message: 'Invalid Account Number',
      },
    ],
  },
  localFormat: {
    rules: [
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.MX.clabe,
        translatedMessage: {
          key: ['verifyAccount.errors.exactlyDigits', `Exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.clabe} digits`],
          value: ACCOUNT_FIELD_MAX_LENGTH.MX.clabe,
        },
      },
      {
        pattern: /^[0-9]+$/,
        translatedMessage: ['verifyAccount.errors.mustBeNumeric', 'Must be numeric'],
      },
    ],
  },
  iban: {
    rules: [
      {
        max: ACCOUNT_FIELD_MAX_LENGTH.iban,
        transform: trim,
        message: `max ${ACCOUNT_FIELD_MAX_LENGTH.iban} characters`,
      },
      {
        transform: trim,
        validator: validateIban,
      },
    ],
  },
  countryCode: {
    rules: [
      {
        required: true,
        transform: trim,
        message: 'Country Required',
      },
    ],
  },
  supplierValidationRegistrationNumber: {
    normalize: (value) => value || undefined,
    rules: [
      {
        transform: trim,
        pattern: SUPPLIER_REGISTRATION_PATTERNS.registrationNumber,
        message: 'Invalid registration number, must be in the format SV-XXXXXXXX',
      },
      {
        transform: trim,
        validator: requireOnOtherFieldValue<CreateValidatedPayeeAccountFormFields>(
          form,
          'storeAsOrganizationalAccount',
          'Registration Number Required',
          true,
        ),
      },
    ],
  },
  storeAsOrganizationalAccount: {
    valuePropName: 'checked',
    initialValue: false,
    rules: [
      {
        validator: onChangeValidateOtherFields(form, ['supplierValidationRegistrationNumber']),
      },
    ],
  },
});

const StyledForm = styled(NsknoxForm)`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 34px;
  }
`;

const StyledCard = styled(Card)`
  padding: 24px 32px;
`;

const WrappingColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;

  & > * {
    margin-left: 32px;
  }
`;

const Column = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 500px;
`;

const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

const SubmitButton = styled(Button)`
  align-self: center;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
`;

const ValidationTypeDropdownIItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;
