import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { FIELD_MAX_LENGTH } from '@supplierRegistration/domain/supplierRegistrationFields';
import { observer } from 'mobx-react';
import { FormFieldDecorators } from '@app/utils/form/form';
import { getLegalIdTypesOfCountryForCompany } from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import { FormComponentProps, WrappedFormUtils } from 'antd/es/form/Form';
import {
  SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST,
  COUNTRY_CODES,
  CountryCode,
  SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST,
} from '@app/domain/countries';
import { SupplierRegistrationRadioButtonOptions } from '@app/domain/commonSupplierRegistration';
import {
  canUseWiringTypesAnswerToBoolean,
  SupplierRegistrationBankAccountFormFields,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
  SupplierValidationRegistrationProps,
} from '@supplierRegistration/domain/supplierRegistration';
import { trim } from '@app/utils/stringUtils';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { Trans, useTranslation } from 'react-i18next';
import { atLeastOneOfFields, onChangeValidateOtherFields, requireOnOtherFieldValue } from '@app/utils/validators';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import { CURRENCIES, CURRENCIES_KEYS } from '@app/domain/currencies';
import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import useInfraStores from '@app/hooks/useInfraStores';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import {
  calculateSwiftOrBankValidatorEnabled,
  doesCountryHasSpecificBankCodeBehavior,
  getAccountTypeByCountry,
  getAdditionalAccountNumberRulesForCountry,
  getAdditionalSwiftRulesForCountry,
  getCountrySupplierRegistrationBankCodeField,
  getCountrySupplierRegistrationBranchCodeField,
  hideAccountTypeByCountry,
  hideBankNameByCountry,
  hideFurtherCreditByCountry,
  SupplierRegistrationField,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { swiftCodeValidator } from '@app/domain/accountDetailsValidators';
import {
  BigText,
  CardSubTitle,
  CardTitle,
  IAgreeText,
  MainCard,
  SmallContinueButton,
  SmallSeparatorWithExtraMargin,
  StyledCheckbox,
  StyledForm,
  StyledFormControlLabel,
} from '@supplierRegistration/routes/Styles';
import useForm from '@app/hooks/useForm';
import ReferringOrg from '@supplierRegistration/components/ReferringOrg';
import PleaseNote from '@supplierRegistration/components/PleaseNote';
import useMountEffect from '@app/hooks/useMountEffect';
import useTheme from '@app/hooks/useTheme';
import { isDefined, isTruthy } from '@app/utils/utils';
import useAppStores from '@app/hooks/useAppStores';
import {
  AsyncInitiateValidationMaskedAccount,
  AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields,
  AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields,
  calculateAsyncInitiateValidationMaskedBankCodeAndBranchCode,
  calculateAsyncInitiateValidationMaskedIbanAndAccountNumber,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import ModalAppContext from '@app/ModalAppContext';
import { showCustomModalAsync } from '@app/components/Modal';
import useModalContext from '@app/hooks/useModalContext';
import IsraelConfirmationModal from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/IsraelConfirmationModal';
import { IsraelBankCodes } from '@app/domain/bankCodes';
import useLeaveConfirmation from '@app/hooks/useLeaveConfirmation';
import CountryDropdown from '@supplierRegistration/components/CountryDropdown';

interface Props
  extends FormComponentProps<SupplierRegistrationBankAccountFormFields & SupplierRegistrationCompanyInfoFormFields>,
    SupplierValidationRegistrationProps {}

const SupplierRegistrationBankAccountDetails: FC<Props> = observer((props) => {
  useLeaveConfirmation();

  const { formValues, onFormSubmit, configuration, isIpsMode, saveValues, isIndividual } = props;

  const { t } = useTranslation();

  const { languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const theme = useTheme<SupplierRegistrationMode>();
  const { brand } = theme;
  const modalContext = useModalContext();

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(formValues?.accountValues?.hasAgreed ?? false);

  const { form, showFormErrors, setShowFormErrors } = useForm(props);

  const asyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.result;

  const getMaxAccountNumberLength = (accountBankCountryCode: CountryCode | undefined): number => {
    const countryCode = accountBankCountryCode || 'default';
    const countryMaxLength =
      ACCOUNT_FIELD_MAX_LENGTH[countryCode]?.accountNumber || ACCOUNT_FIELD_MAX_LENGTH['default'].accountNumber;
    return Math.max(countryMaxLength, ACCOUNT_FIELD_MAX_LENGTH.iban);
  };

  const accountBankCountryCode: CountryCode | undefined =
    form.getFieldValue('accountBankCountryCode') || asyncProcessMaskedData?.account.countryCode;
  const accountCurrency = form.getFieldValue('currency');
  const canUseAchAnswer = canUseWiringTypesAnswerToBoolean(form.getFieldValue('canUseAch'));
  const isCountryCodeUS = accountBankCountryCode === COUNTRY_CODES.UnitedStates;
  const isCountryCodeJP = accountBankCountryCode === COUNTRY_CODES.Japan;
  const isCountryCodeBR = accountBankCountryCode === COUNTRY_CODES.Brazil;
  const isCurrencyJPY = accountCurrency === CURRENCIES_KEYS.JPY;
  const accountNumberMaxLength = getMaxAccountNumberLength(accountBankCountryCode);
  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const isAsync = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.isResolved();

  const supplierCountryCode = form.getFieldValue('country');
  const legalIdTypesOfCountry = getLegalIdTypesOfCountryForCompany(supplierCountryCode);

  useEffect((): void => {
    // Prevent resetting the field in case we want to apply a saved value
    if (isCountryCodeUS) {
      form.resetFields(['bankCode']);
    } else {
      form.resetFields(['abaRouting', 'canUseAch']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [accountBankCountryCode, isCountryCodeUS]);

  useEffect((): void => {
    if (doesCountryHasSpecificBankCodeBehavior(accountBankCountryCode)) {
      form.resetFields(['bankCode', 'branchCode']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeUS, accountBankCountryCode, isCountryCodeJP]);

  useEffect((): void => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [legalIdTypesOfCountry]);

  useEffect((): void => {
    form.validateFields(['bankCode'], { force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [canUseAchAnswer]);

  useEffect((): void => {
    if (isCountryCodeJP && isCurrencyJPY) {
      form.resetFields(['accountType']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeJP, isCurrencyJPY]);

  useEffect((): void => {
    if (isCountryCodeBR) {
      form.resetFields(['accountType']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeBR]);

  useMountEffect((): VoidFunction => (): void => saveValues?.(form.getFieldsValue()));

  const useDefaultBankCode = accountBankCountryCode === COUNTRY_CODES.Japan && accountCurrency !== CURRENCIES_KEYS.JPY;
  const countryBankCodeField = getCountrySupplierRegistrationBankCodeField(form, accountBankCountryCode, useDefaultBankCode);
  const countryBranchCodeField = getCountrySupplierRegistrationBranchCodeField(form, accountBankCountryCode, useDefaultBankCode);

  const asyncInitiateValidationMaskedIbanAndAccountNumber = calculateAsyncInitiateValidationMaskedIbanAndAccountNumber(
    asyncProcessMaskedData?.account,
  );
  const asyncInitiateValidationMaskedBankCodeAndBranchCode = calculateAsyncInitiateValidationMaskedBankCodeAndBranchCode(
    !!countryBankCodeField,
    !!countryBranchCodeField,
    asyncProcessMaskedData?.account,
  );

  const fieldDecorators = createFieldDecorators(
    form,
    asyncProcessMaskedData?.account,
    asyncInitiateValidationMaskedIbanAndAccountNumber,
    asyncInitiateValidationMaskedBankCodeAndBranchCode,
    accountBankCountryCode,
    !hideAccountTypeByCountry(accountBankCountryCode, form, !!isIndividual),
    canUseAchAnswer,
    formValues,
    accountNumberMaxLength,
    countryBankCodeField,
    countryBranchCodeField,
    !hideBankNameByCountry(accountBankCountryCode),
  );

  const registerDisableClick = (): void => {
    setShowFormErrors('all');
    form.validateFields();
  };

  const changeAgreedToTerms = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHasAgreedToTerms(event.currentTarget.checked);

    setShowFormErrors('all');
    // this is a hack to prevent the 'remember me' button from enabling the 'continue' on accident
    form.validateFields();
  };

  const showUserPermissionsModal = async (): Promise<boolean | void> => {
    let hasAgreedIsraelTerms;
    await showCustomModalAsync(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <IsraelConfirmationModal
            onDone={(result: boolean | undefined): void => {
              hasAgreedIsraelTerms = result;
              onDone();
            }}
          />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
    );
    return hasAgreedIsraelTerms ? true : undefined;
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(async (errors: Object, values: SupplierRegistrationBankAccountFormFields) => {
      if (errors) {
        return;
      }

      let hasAgreedIsraelTerms;
      if (accountBankCountryCode == COUNTRY_CODES.Israel) {
        hasAgreedIsraelTerms = await showUserPermissionsModal();
        if (!hasAgreedIsraelTerms) {
          return;
        }
      }

      onFormSubmit({ ...values, hasAgreed: hasAgreedToTerms, hasAgreedIsrael: hasAgreedIsraelTerms });
    });
  };

  const renderIsraelBankCodeDropdown = (name: string, placeholder: string): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.country')}
        name={name}
        dataTestId={name}
        placeholder={t<string>(placeholder)}
        isSearchable
      >
        {IsraelBankCodes.sort((a, b) => a.code - b.code).map((bankCode) => {
          return (
            <CountryDropdownItem
              key={bankCode.code}
              keywords={[bankCode.code, bankCode.name]}
              value={bankCode.code}
              textWhenSelected={`${bankCode.code} - ${bankCode.name}`}
            >
              <div>{`${bankCode.code} - ${bankCode.name}`}</div>
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const renderCurrencyDropdown = (name: string): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.currency')}
        name={name}
        dataTestId={name}
        placeholder={t<string>('supplierValidation.supplierRegister.currency')}
        isSearchable
      >
        {Object.keys(CURRENCIES).map((currency) => {
          return (
            <CountryDropdownItem key={currency} keywords={[currency]} value={currency} textWhenSelected={currency}>
              <div>{currency}</div>
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const renderAccountTypeDropdown = (accountCountryCode: string | undefined): ReactElement => {
    const accountTypes = getAccountTypeByCountry(accountCountryCode);
    return accountTypes ? (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.accountType')}
        name='account-type-drop'
        dataTestId='account-type-drop'
        placeholder={t<string>('supplierValidation.supplierRegister.accountType')}
        isSearchable
      >
        {Object.entries(accountTypes).map(([type, value]) => {
          return (
            <AccountTypeDropdownItem
              key={type}
              keywords={[type]}
              value={type}
              textWhenSelected={`supplierValidation.supplierRegister.accountTypes.${type}`}
            >
              {t(`supplierValidation.supplierRegister.accountTypes.${type}`)}
            </AccountTypeDropdownItem>
          );
        })}
      </NakedDropdown>
    ) : (
      <></>
    );
  };

  const renderBankAccountDomesticField = (
    bankCodeField: SupplierRegistrationField,
    accountCountryCode: string | undefined,
  ): ReactNode => {
    if (asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'hidden') {
      return;
    }

    if (asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'disabled') {
      bankCodeField = getCountrySupplierRegistrationBankCodeField(form);
    }

    if (accountCountryCode === COUNTRY_CODES.Israel) {
      return (
        <SupplierRegistrationFormItemBox
          fieldName='bankCode'
          fieldDecoratorOptions={fieldDecorators.bankCode}
          hasPrefilledData={isDefined(asyncProcessMaskedData?.account.bankCode)}
        >
          {renderIsraelBankCodeDropdown('drp-supp-reg-account-bank-Code', `supplierValidation.supplierRegister.bankCode`)}
        </SupplierRegistrationFormItemBox>
      );
    }

    return (
      <SupplierRegistrationFormItemBox
        fieldName='bankCode'
        fieldDecoratorOptions={fieldDecorators.bankCode}
        hasPrefilledData={asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'disabled'}
      >
        <NakedFormInput
          name={bankCodeField.id}
          dataTestId={bankCodeField.id}
          type={bankCodeField.inputType}
          placeholder={t<string>(
            asyncInitiateValidationMaskedBankCodeAndBranchCode.isBankCodeMayContainBranchCode
              ? [
                  !useDefaultBankCode &&
                    `supplierValidation.supplierRegister.${accountBankCountryCode}.bankCodeMayContainBranchCode`,
                  !useDefaultBankCode && `supplierValidation.supplierRegister.${accountBankCountryCode}.bankCode`,
                  'supplierValidation.supplierRegister.bankCodeMayContainBranchCode',
                ].filter(isTruthy)
              : [
                  !useDefaultBankCode && `supplierValidation.supplierRegister.${accountBankCountryCode}.bankCode`,
                  'supplierValidation.supplierRegister.bankCode',
                ].filter(isTruthy),
          )}
          disableSuggestion
        />
      </SupplierRegistrationFormItemBox>
    );
  };

  const renderBranchAccountDomesticField = (branchCodeField: SupplierRegistrationField | undefined): ReactNode => {
    if (asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'hidden') {
      return;
    }

    if (asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'disabled') {
      branchCodeField = {
        id: 'inpt-supp-reg-branch-code',
        inputType: 'text',
        decoratorRules: [],
      };
    }

    if (!branchCodeField) {
      return;
    }

    return (
      <SupplierRegistrationFormItemBox
        fieldName='branchCode'
        fieldDecoratorOptions={fieldDecorators.branchCode}
        hasPrefilledData={asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'disabled'}
      >
        <NakedFormInput
          name={branchCodeField.id}
          dataTestId={branchCodeField.id}
          type={branchCodeField.inputType}
          placeholder={t<string>([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.branchCode`,
            'supplierValidation.supplierRegister.branchCode',
          ])}
          disableSuggestion
        />
      </SupplierRegistrationFormItemBox>
    );
  };

  function getCardSubTitle(): ReactNode {
    if (isIpsMode) {
      return <>{t('supplierValidation.registerSteps.bankAccountDetails.subTitleIps')}</>;
    }

    if (isAsync) {
      return (
        <Trans i18nKey='supplierValidation.registerSteps.bankAccountDetails.subTitleAsync' values={{ customerName }}>
          Complete missing bank account details. Details already provided to {{ customerName }} are prefilled. To modify any of
          these, please contact {{ customerName }}.
        </Trans>
      );
    }

    return (
      <Trans i18nKey='supplierValidation.registerSteps.bankAccountDetails.subTitle'>
        Enter the bank account details that you have <strong>provided to the payer</strong>
      </Trans>
    );
  }

  return (
    <>
      <MainCard fontFamily={theme.fontFamily}>
        <StyledForm onSubmit={handleOk} showErrors={showFormErrors} form={form} setShowErrors={setShowFormErrors}>
          {configuration?.clientName && !brand && <ReferringOrg orgName={configuration.clientName} />}
          <CardTitle>{t('supplierValidation.registerSteps.bankAccountDetails.title')}</CardTitle>
          <CardSubTitle>{getCardSubTitle()}</CardSubTitle>
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='accountBankCountryCode'
              fieldDecoratorOptions={fieldDecorators.accountBankCountryCode}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.countryCode)}
            >
              <CountryDropdown
                name='drp-supp-reg-account-bank-country'
                placeholder={t<string>('supplierValidation.supplierRegister.accountCountry')}
                excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
              />
            </SupplierRegistrationFormItemBox>
            <SupplierRegistrationFormItemBox
              fieldName='currency'
              fieldDecoratorOptions={fieldDecorators.currency}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.currency)}
            >
              {renderCurrencyDropdown('drp-supp-reg-account-currency')}
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SmallSeparatorWithExtraMargin />
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='swiftCode'
              fieldDecoratorOptions={fieldDecorators.swiftCode}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.swiftCode)}
            >
              <NakedFormInput
                name='inpt-supp-reg-swift-code'
                dataTestId='inpt-supp-reg-swift-code'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.swift')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {asyncInitiateValidationMaskedIbanAndAccountNumber.showAccountNumber && (
              <SupplierRegistrationFormItemBox
                fieldName='accountNumber'
                fieldDecoratorOptions={fieldDecorators.accountNumber}
                hasPrefilledData={isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber.accountNumber)}
              >
                <NakedFormInput
                  name='inpt-supp-reg-account-number'
                  dataTestId='inpt-supp-reg-account-number'
                  type='string'
                  placeholder={t<string>(
                    asyncInitiateValidationMaskedIbanAndAccountNumber.isAccountOrIban
                      ? [
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.accountOrIban`,
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.account`,
                          'supplierValidation.supplierRegister.accountOrIban',
                        ]
                      : [
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.account`,
                          'supplierValidation.supplierRegister.account',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            {asyncInitiateValidationMaskedIbanAndAccountNumber.showIban && (
              <SupplierRegistrationFormItemBox
                fieldName='ibanForAsyncInitiateValidation'
                fieldDecoratorOptions={fieldDecorators.ibanForAsyncInitiateValidation}
                hasPrefilledData={isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber.iban)}
              >
                <NakedFormInput
                  name='inpt-supp-reg-iban'
                  dataTestId='inpt-supp-reg-iban'
                  type='string'
                  placeholder={t<string>([
                    `supplierValidation.supplierRegister.${accountBankCountryCode}.iban`,
                    'supplierValidation.supplierRegister.iban',
                  ])}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            {renderBankAccountDomesticField(countryBankCodeField, accountBankCountryCode)}
            <HiddenableFormItemBox
              fieldName='furtherCredit'
              fieldDecoratorOptions={fieldDecorators.furtherCredit}
              hidden={hideFurtherCreditByCountry(accountBankCountryCode)}
            >
              <NakedFormInput
                name='inpt-supp-reg-further-credit'
                dataTestId='inpt-supp-reg-further-credit'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.furtherCredit')}
                disableSuggestion
              />
            </HiddenableFormItemBox>
            {renderBranchAccountDomesticField(countryBranchCodeField)}
            <HiddenableFormItemBox
              hidden={!isCountryCodeUS}
              fieldName='abaRouting'
              fieldDecoratorOptions={isCountryCodeUS ? fieldDecorators.abaRouting : {}}
            >
              <NakedFormInput
                name='inpt-supp-reg-us-aba-routing'
                dataTestId='inpt-supp-reg-us-aba-routing'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.US.abaRouting')}
                disableSuggestion
              />
            </HiddenableFormItemBox>
            <HiddenableDiv hidden={hideAccountTypeByCountry(accountBankCountryCode, form, !!isIndividual)}>
              <SupplierRegistrationFormItemBox
                fieldName='accountType'
                fieldDecoratorOptions={fieldDecorators.accountType}
                hasPrefilledData={false}
              >
                {renderAccountTypeDropdown(accountBankCountryCode)}
              </SupplierRegistrationFormItemBox>
            </HiddenableDiv>
            <HiddenableDiv hidden={hideBankNameByCountry(accountBankCountryCode)}>
              <SupplierRegistrationFormItemBox
                fieldName='bankName'
                fieldDecoratorOptions={fieldDecorators.bankName}
                hasPrefilledData={false}
              >
                <NakedFormInput
                  name='inpt-supp-reg-bank-name'
                  dataTestId='inpt-supp-reg-bank-name'
                  type='string'
                  placeholder={t<string>('supplierValidation.supplierRegister.bankName')}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            </HiddenableDiv>
          </InputGrid>
          <HiddenableDiv hidden={!isCountryCodeUS}>
            <BigText>
              <Trans i18nKey='supplierValidation.supplierRegister.US.achEligible'>
                Select "Yes" if your account is set up to <b>send</b> ACH payments.
                <br />
                Otherwise select "No".
              </Trans>
            </BigText>
            <SupplierRegistrationFormItemBox
              fieldName='canUseAch'
              fieldDecoratorOptions={fieldDecorators.canUseAch}
              appearance='none'
              hasPrefilledData={false}
            >
              <StyledRTLFormRadioGroup
                accessibilityLabel={t('supplierValidation.accessibility.supplierRegister.US.achEligible')}
                isRTL={languageStore.isRTL}
                id='radio-group-can-use-ach'
                dataTestId='radio-group-can-use-ach'
                options={[
                  {
                    value: SupplierRegistrationRadioButtonOptions.yes,
                    label: t('general.yes'),
                    dataTestId: 'yes-ach',
                  },
                  {
                    value: SupplierRegistrationRadioButtonOptions.no,
                    label: t('general.no'),
                    dataTestId: 'no-ach',
                  },
                ]}
              />
            </SupplierRegistrationFormItemBox>
          </HiddenableDiv>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                id='cbox-supp-reg-agree-terms'
                data-testid='cbox-supp-reg-agree-terms'
                checked={hasAgreedToTerms}
                onChange={changeAgreedToTerms}
              />
            }
            label={
              <IAgreeText>
                <Trans i18nKey='supplierValidation.supplierRegister.iAgree'>
                  By completing this form and clicking “Register”, I confirm that I am authorized to do so, and authorize nsKnox
                  to process the details I provide, subject to the nsKnox{' '}
                  <a href='https://nsknox.net/terms-of-use/' target='_blank' rel='noreferrer'>
                    terms of use
                  </a>{' '}
                  and nsKnox{' '}
                  <a href='https://nsknox.net/privacy-policy/' target='_blank' rel='noreferrer'>
                    privacy policy
                  </a>
                  .
                </Trans>
              </IAgreeText>
            }
          />
          <SmallContinueButton
            id='btn-supp-reg-bank-account-continue'
            disabled={!hasAgreedToTerms}
            onClick={handleOk}
            onDisabledClick={registerDisableClick}
            colorScheme={theme.continueButtonColor}
          >
            {t('supplierValidation.supplierRegister.register')}
          </SmallContinueButton>
        </StyledForm>
      </MainCard>
      <PleaseNote isIpsMode={isIpsMode} configuration={configuration} />
    </>
  );
});

export default Form.create<Props>()(SupplierRegistrationBankAccountDetails);

const createFieldDecorators = (
  form: WrappedFormUtils,
  asyncProcessMaskedAccount: AsyncInitiateValidationMaskedAccount | undefined,
  asyncInitiateValidationMaskedIbanAndAccountNumber: AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields | undefined,
  AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields:
    | AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields
    | undefined,
  accountBankCountryCode: COUNTRY_CODES | undefined,
  requireAccountType: boolean,
  canUseAch: boolean | undefined,
  formInitialValues: SupplierRegistrationFormValuesState,
  accountNumberMaxLength: number,
  countryBankCodeField: SupplierRegistrationField,
  countryBranchCodeField: SupplierRegistrationField | undefined,
  requireBankName: boolean,
): FormFieldDecorators<Omit<SupplierRegistrationBankAccountFormFields, 'hasAgreed' | 'hasAgreedIsrael'>> => ({
  swiftCode: {
    initialValue: asyncProcessMaskedAccount?.swiftCode ?? formInitialValues.accountValues?.swiftCode ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.swiftCode),
    rules: [
      ...getAdditionalSwiftRulesForCountry(accountBankCountryCode, form),
      {
        enabled: calculateSwiftOrBankValidatorEnabled(accountBankCountryCode, form),
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['bankCode', 'branchCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
      {
        validator: swiftCodeValidator,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidSwiftCode',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  bankCode: {
    initialValue:
      AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.bankCode ??
      formInitialValues.accountValues?.bankCode ??
      undefined,
    disableAllRules: AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.bankCodeState !== 'enabled',
    rules: [
      ...(countryBankCodeField?.decoratorRules ?? []),
      {
        enabled:
          accountBankCountryCode &&
          ![
            COUNTRY_CODES.UnitedStates,
            COUNTRY_CODES.Argentina,
            COUNTRY_CODES.Vietnam,
            ...SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST,
          ].includes(accountBankCountryCode),
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['swiftCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
      {
        enabled: accountBankCountryCode === COUNTRY_CODES.UnitedStates && canUseAch,
        transform: trim,
        validator: requireOnOtherFieldValue<SupplierRegistrationBankAccountFormFields>(
          form,
          'canUseAch',
          convertToTranslatedMessage(`supplierValidation.supplierRegister.US.errors.invalidBankCode`),
          SupplierRegistrationRadioButtonOptions.yes,
        ),
      },
      {
        enabled: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        len: ACCOUNT_FIELD_MAX_LENGTH.US.achRouting,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.US.errors.bankCodeLength`,
      },
      {
        transform: trim,
        validator: onChangeValidateOtherFields<SupplierRegistrationBankAccountFormFields>(form, 'abaRouting'),
      },
    ],
  },
  branchCode: {
    initialValue:
      AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.branchCode ??
      formInitialValues.accountValues?.branchCode ??
      undefined,
    disableAllRules: AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.branchCodeState !== 'enabled',
    rules: [
      ...(countryBranchCodeField?.decoratorRules ?? []),
      {
        enabled: !!countryBranchCodeField,
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['swiftCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
    ],
  },
  abaRouting: {
    initialValue: formInitialValues.accountValues?.abaRouting ?? undefined,
    rules: [
      {
        required: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.missingAbaRouting',
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.US.abaRouting,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.abaRoutingLength',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.invalidAbaRouting',
      },
    ],
  },
  accountNumber: {
    initialValue:
      asyncInitiateValidationMaskedIbanAndAccountNumber?.accountNumber ??
      formInitialValues.accountValues?.accountNumber ??
      undefined,
    disableAllRules: isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber?.accountNumber),
    rules: [
      ...getAdditionalAccountNumberRulesForCountry(accountBankCountryCode),
      {
        required: true,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.invalidAccount`,
          'supplierValidation.supplierRegister.errors.invalidAccount',
        ],
      },
      {
        max: accountNumberMaxLength,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: accountNumberMaxLength,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidAccountNumber',
      },
    ],
  },
  ibanForAsyncInitiateValidation: {
    initialValue: asyncInitiateValidationMaskedIbanAndAccountNumber?.iban,
  },
  accountBankCountryCode: {
    initialValue: asyncProcessMaskedAccount?.countryCode ?? formInitialValues.accountValues?.accountBankCountryCode ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.countryCode),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAccountBankCountry',
      },
      {
        max: FIELD_MAX_LENGTH.countryCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.countryCode,
        },
      },
    ],
  },
  currency: {
    initialValue: asyncProcessMaskedAccount?.currency ?? formInitialValues.accountValues?.currency ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.currency),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingCurrency',
      },
      {
        max: FIELD_MAX_LENGTH.currency,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.currency,
        },
      },
    ],
  },
  furtherCredit: {
    initialValue: formInitialValues.accountValues?.furtherCredit ?? undefined,
    rules: [
      {
        max: FIELD_MAX_LENGTH.furtherCredit,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.furtherCredit,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  canUseAch: {
    initialValue: formInitialValues.accountValues?.canUseAch ?? undefined,
    rules: [
      {
        required: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAnswer',
      },
      {
        transform: trim,
        validator: onChangeValidateOtherFields<SupplierRegistrationBankAccountFormFields>(form, 'bankCode'),
      },
    ],
  },
  accountType: {
    initialValue: formInitialValues.accountValues?.accountType ?? undefined,
    rules: [
      {
        required: requireAccountType,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAccountType',
      },
    ],
  },
  bankName: {
    initialValue: formInitialValues.accountValues?.bankName ?? undefined,
    rules: [
      {
        required: requireBankName,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBankName',
      },
    ],
  },
});

const HiddenableFormItemBox = styled(FormItemBox)<{ hidden: boolean }>`
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

styled(FormRadioGroup)`
  margin-bottom: 22px;

  .MuiFormControlLabel-root {
    margin-inline-end: 16px !important;
    margin-inline-start: -11px !important;
  }
`;

styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const CountryDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const AccountTypeDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const StyledRTLFormRadioGroup = styled(FormRadioGroup)<{ isRTL?: boolean }>`
  ${(p): string => (p.isRTL ? 'margin-right: -16px' : '')}
`;

const HiddenableDiv = styled.div<{ hidden: boolean }>`
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;
